import React,{useState} from 'react'
import './Login.css'
import ControlRoomImage from '../../Images/ControlRoomBookingImg.webp';
import MelodiumLogo from '../../Images/MelodiumLogo.webp'
import {Link,useNavigate} from 'react-router-dom';
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const API_BASE = process.env.REACT_APP_API_BASE;

function Login() {

  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    UserID: "",
    Password: "",
  });
  const { username, password } = inputValue;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>{
    alert(err)
    };
    const handleSuccess = (msg) =>
    {
      toast("Logged In", {
        position: "top-left",
        autoClose: 5000, // Adjust as needed
        type: "success", // or "info", "warning", "error"
      });
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const { data } = await axios.post(
          `${API_BASE}/login`,
          {
            ...inputValue,
          },
          { withCredentials: true }
        );
        
        const { success, message,token } = data;
        if (success) {
          handleSuccess(message);
          // Navigate only after a successful login
          navigate("/Dashboard/Booking");
        } else {
          handleError(message);
        }
      } catch (error) {
        console.error(error);
        // Handle network or server errors
        handleError("An error occurred during login");
      }
      // Clear input values regardless of success or failure
      setInputValue({
        ...inputValue,
        UserID: "",
        Password: "",
      });
    };

  return (
    <>
      <div className="login-background">     
          <img id='loginBG' src={ControlRoomImage} alt="" />
        <div className="login-rectangle">
        <div className="logo-container">
          <img id='MelodiumLogo' src={MelodiumLogo} alt="" />
          </div>
          <h1 id='login-text'>LOGIN</h1>
          <form onSubmit={handleSubmit}>
            <label id='login-username' htmlFor="enter-username">Username:</label>
            <input type="text" name="UserID" placeholder='Username' id='login-enter-username' onChange={handleOnChange} required/>
            <label id='login-password' htmlFor="enter-password">Password:</label>
            <input type="password" name="Password" placeholder='Password' id='login-enter-password' onChange={handleOnChange} required/>
            <input type="submit" value="Login" className='login-submit-container' required/>
          </form>
        </div>
        <ToastContainer/>
      </div>
    </>
  )
}

export default Login