import React,{useEffect,useRef,useState} from "react";
import "./Pagesix.css"
import ServiceCard from '../Mini/ServiceCard'
function Pagesix(){
    const ser1 = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/ciukwdb3b11uodkuvd0b"
    const ser2 = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/kmw39ocpgkejdqzbhrkx"
    const ser3 = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/pavalxqdamzjovpvt2zu"
    const ser4 = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/cyov9gvlcpduhrhfgjrg"
    const ser5 = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/milxpuk7zctchtipguk6"
    const ser6 = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/hlscgrynhqvvnpk4zmpg"
    const serImages1Ref = useRef(null);
  const serImages2Ref = useRef(null);
  const serImages3Ref = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    checkIfMobile();

    const handleScroll = () => {
      const rect1 = serImages1Ref.current?.getBoundingClientRect();
      const rect2 = serImages2Ref.current?.getBoundingClientRect();
      const rect3 = serImages3Ref.current?.getBoundingClientRect();

      if (rect1 && rect1.top < window.innerHeight && rect1.bottom >= 0) {
        serImages1Ref.current.classList.add("animate");
      }

      if (rect2 && rect2.top < window.innerHeight && rect2.bottom >= 0) {
        serImages2Ref.current.classList.add("animate");
      }

      if (rect3 && rect3.top < window.innerHeight && rect3.bottom >= 0) {
        serImages3Ref.current.classList.add("animate");
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
    return(
        <>
            <div className="pagesixcontainer">
                <div className="circleser">
                    <svg xmlns="http://www.w3.org/2000/svg" width="117" height="131" viewBox="0 0 117 131" fill="none">
                        <g filter="url(#filter0_b_312_103)">
                            <ellipse cx="105" cy="22" rx="105" ry="109" fill="#F5BC25" fillOpacity="0.5"/>
                        </g>
                        <defs>
                            <filter id="filter0_b_312_103" x="-10" y="-97" width="230" height="238" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_312_103"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_312_103" result="shape"/>
                            </filter>
                        </defs>
                    </svg>
                </div>

                <div className="herotextser">
                    <h1 >Want to know more about our service rates?  Click <span>'Know More'</span></h1>
                </div>
                <div className="subser">
                    <h1>S E R V I C E S</h1>
                </div>
                {isMobile ? (
                <div className="serimages">
                <div
                    className={`serimages-1 ${
                    serImages1Ref.current ? "animate" : ""}`}
                    ref={serImages1Ref}
                >
                    <ServiceCard imgsource={ser1} title="Music Production" />
                    <ServiceCard imgsource={ser2} title="Audio Recording" />
                </div>
                <div
                    className={`serimages-2 ${
                    serImages2Ref.current ? "animate" : ""
                    }`}
                    ref={serImages2Ref}
                >
                    <ServiceCard imgsource={ser3} title="Session Artist" />
                    <ServiceCard imgsource={ser4} title="Photo & Video" />

                </div>
                <div
                    className={`serimages-3 ${
                    serImages3Ref.current ? "animate" : ""
                    }`}
                    ref={serImages3Ref}
                >
                    <ServiceCard imgsource={ser5} title="Mixing & Mastering" />
                    <ServiceCard imgsource={ser6} title="Jam Room" />
                </div>
            </div>
            ) : (
                <div className="serimages">
                <div
                    className={`serimages-1 ${
                    serImages1Ref.current ? "animate" : ""}`}
                    ref={serImages1Ref}
                >
                    <ServiceCard imgsource={ser1} title="Music Production" />
                    <ServiceCard imgsource={ser2} title="Audio Recording" />
                    <ServiceCard imgsource={ser3} title="Session Artist" />
                </div>
                <div
                    className={`serimages-2 ${
                    serImages2Ref.current ? "animate" : ""
                    }`}
                    ref={serImages2Ref}
                >
                    <ServiceCard imgsource={ser4} title="Photo & Video" />
                    <ServiceCard imgsource={ser5} title="Mixing & Mastering" />
                    <ServiceCard imgsource={ser6} title="Jam Room" />
                </div>
            </div>
      )}
               <div className="bottom-service-rectangle-container">
               <div className="servicecircle">
               <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
                    <g filter="url(#filter0_b_767_6)">
                        <circle cx="22.5" cy="22.5" r="22.5" transform="rotate(-90 22.5 22.5)" fill="#F5BC25"/>
                    </g>
                    <defs>
                    <filter id="filter0_b_767_6" x="-10" y="-10" width="65" height="65" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_767_6"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_767_6" result="shape"/>
                    </filter>
                    </defs>
                    </svg>
               </div>
                <div className="service1rect"></div>
                <div className="service2rect"></div>
               </div>
            </div>
        </>
    );
}
export default Pagesix;