import React from "react";
import { useNavigate } from "react-router-dom";
import "./ServiceCard.css";

export const ServiceCard = ({ imgsource, title }) => {
  const navigate = useNavigate()
  const navToPricing = (title) =>
  {
      navigate(`/Pricing/${title}`);
      console.log("Navigate to price")
  }

  return (
    <div className="servicecardcontainer"  onClick={() => navToPricing(title)}>
      <img src={imgsource} className="service1" alt={title} />
      <div className="servicetitle">
        <h1>{title}</h1>
      </div>
      <div className="knowmore">
        <h1>Know More</h1>
      </div>
    </div>
  );
};

export default ServiceCard;
