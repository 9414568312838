import React,{useState,useEffect} from 'react'
import { useCookies } from "react-cookie";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './addUser.css'
import DashboardMenu from '../Mini/DashboardMenu'
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const API_BASE = process.env.REACT_APP_API_BASE;


function generateRandomPassword(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomPassword = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomPassword += characters.charAt(randomIndex);
  }

  return randomPassword;
}
function Login() {
  const [Users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  useEffect(() => {
    const verifyCookie = async () => {
      if(cookies.token==="undefined")
        {
          navigate("/login");
        }
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
        `${API_BASE}/verify`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      return status
        ? console.log("Booking")
        : (removeCookie("token"), alert("moving"),navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);
  useEffect(() => {
    // Generate a random password and update the state
    const randomPassword = generateRandomPassword(8);
    setInputValue({
      ...inputValue,
      Password: randomPassword,
    });
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE}/Users`);
        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const usersData = await response.json();
        const Data = usersData.users
        setUsers(Data);
        console.log(Data)
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // Call the function to fetch users
    fetchUsers();

  }, []);
  const [inputValue, setInputValue] = useState({
    UserID: "",
    Password: "",
  });
  const {UserID,Password} = inputValue;
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const handleError = (err) =>
    {
      alert(err)
    }
  const handleSuccess = (userID) =>
  {
    toast(`User ${userID} added successfully`, {
      position: "top-left",
      autoClose: 2000, // Adjust as needed
      type: "success", // or "info", "warning", "error"
    });
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements['enter-name'].value;
    const email = e.target.elements['enter-email'].value;
    try {
      console.log(inputValue)
      const { data } = await axios.post(
        `${API_BASE}/signup`,
        {
            ...inputValue
        },
        { withCredentials: true }
      );
  
      const { success, message } = data;
      if (success) {
        handleSuccess(inputValue.UserID);
        await fetch(API_BASE + '/sendUserEmail', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            Name: name,  
            Email:email,
            Username: inputValue.UserID,
            Password: inputValue.Password
          }),
          
        }
          );
        setTimeout(() => {
          navigate("/Dashboard/AddUser");
        }, 1000);
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }
  
    setInputValue({
      ...inputValue,
      UserID: "",
      Password: "",
    });
  };
  const handleRemovalSubmit = async (e) => {
    try {
      e.preventDefault();
  
      // Access the form elements by name
      const userID = e.target.elements['User-removal'].value;
  
      // Send a DELETE request to your server's endpoint with the userID
      const response = await fetch(`${API_BASE}/delete-user/${userID}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete user');
      }
      toast(`User ${userID} deleted successfully`, {
        position: "top-left",
        autoClose: 5000, // Adjust as needed
        type: "success", // or "info", "warning", "error"
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <DashboardMenu/>
        
        <h1 className='User-title'>Add User</h1>
        <div className="User-line"/>
        <div className="adding-user-rectangle">
          <form onSubmit={handleFormSubmit}>
            <label id='username' htmlFor="enter-name">Name:</label>
            <input type="text" name="enter-name" placeholder='Name' id='enter-name' required/>
            <label id='password' htmlFor="enter-email">Email:</label>
            <input type="text" name="enter-email" placeholder='Email' id='enter-email' required/>
            <label id='enter-username-label' htmlFor="enter-username" >Username:</label>
            <input type="text" name="UserID" placeholder='UserID' id='enter-username' onChange={handleOnChange}/>
            <input type="submit" value="Submit" className='login-user-container' required/>
          </form> 
       </div>
       <div className="Removing-user-rectangle">
          <form onSubmit={handleRemovalSubmit}>
          <select name="select-option" id="User-removal">
            {Users.map(user => (
                <option key={user} value={user}>
                  {user}
                </option>
              ))}
            </select>
            <input type="submit" className="Remove-user-button" value="Remove User" />
          </form>
          <ToastContainer/>
       </div>
    </>
  )
}

export default Login