import React,{useEffect, useState,useRef } from "react";
import YoutubeVideo from "../Mini/YoutubeVideo";
import "./Pagetwo.css"
import { debounce } from 'lodash';


function Pagetwo (){
    const mike2 = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/jabebvkmrdurodz5sdkt`
    const pagethree = "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/uss13ahenyzqcmms56rl"
    const [inView1, setInView1] = useState(false);

    const rect1Ref = useRef(null);
    const hero2Ref = useRef(null);
    const page2imgRef = useRef(null);
    const rect4Ref = useRef(null);
    const [containerDimensions, setContainerDimensions] = useState({ width: 0, height: 0 });

    function extractVideoId(youtubeUrl) {
      const url = new URL(youtubeUrl);
      const searchParams = new URLSearchParams(url.search);
      return searchParams.get('v');
    }

    useEffect(() => {
      const handleScroll = debounce(() => {
        const rect1 = rect1Ref.current?.getBoundingClientRect();
        const hero2 = hero2Ref.current?.getBoundingClientRect();
        const page2img = page2imgRef.current?.getBoundingClientRect();
        const rect4 = rect4Ref.current?.getBoundingClientRect();
  
        if (rect1 && rect1.top !== undefined && !inView1 && (rect1.top <= window.innerHeight && rect1.bottom >= 0)) {
          setInView1(true);
        }
        if (hero2 && hero2.top !== undefined && !inView1 && (hero2.top <= window.innerHeight && hero2.bottom >= 0)) {
          setInView1(true);
        }
        if (page2img && page2img.top !== undefined && !inView1 && (page2img.top <= window.innerHeight && page2img.bottom >= 0)) {
          setInView1(true);
        }
        if (rect4 && rect4.top !== undefined && !inView1 && (rect4.top <= window.innerHeight && rect4.bottom >= 0)) {
          setInView1(true);
        }
      }, 50);    
      const apiBaseUrl = process.env.REACT_APP_API_BASE;
      fetch(`${apiBaseUrl}/GetVideo/1`)
      .then(response => response.json())
      .then(data => {
        if (data.videoURL) {
          const videoId = extractVideoId(data.videoURL);
          setVideoUrl(videoId);
        } 
      })
      .catch(error => console.error('Error fetching video 1 URL:', error));
  
    
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [inView1]);
    
    useEffect(() => {
      const handleResize = () => {
        updateContainerDimensions();
      };
  
      // Update container dimensions on initial load
      updateContainerDimensions();
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    const updateContainerDimensions = () => {
      const { width, height } = page2imgRef.current.getBoundingClientRect();
      setContainerDimensions({ width, height });
    };
  
    const { width, height } = containerDimensions;
    const [videoUrl, setVideoUrl] = useState(null);
    return (
        <>
        <div className="pagetwocontainer">
          <div className="page21container">
          <div ref={rect1Ref} className={`rectangle3 ${inView1 ? "animate" : ""}`}></div>
          <div className="herotext-one">
          <h1 ref={hero2Ref} className={`herotext2 ${inView1 ? "animate" : ""}`}>A <span>State of the Art</span> studio right here in <span>Mangalore</span></h1>
            <h1 className="subtext2">
                Welcome to our world-class studio, nestled in the heart of Mangalore. Our cutting-edge facility is thoughtfully designed to cater to a wide spectrum of creative and professional needs, making it the ultimate destination for artists, musicians, filmmakers, content creators, and more.
            </h1>
          </div>
          <div className="line-circle-divider-container-one">
          <div className="rectangle32"></div>
          <div className="circle23">
            <svg xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
            <g filter="url(#filter0_b_576_7)">
                <ellipse cx="18.5" cy="19" rx="18.5" ry="19" fill="#F5BC25"/>
            </g>
            <defs>
                <filter id="filter0_b_576_7" x="-10" y="-10" width="57" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_576_7"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_576_7" result="shape"/>
                </filter>
            </defs>
            </svg>
          </div>
          <div className="rectangle33"></div>
          </div>
          <div className="video-one-container">
          <div className="circle2">
                <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
                <g filter="url(#filter0_b_282_11)">
                    <circle cx="100" cy="100" r="100" fill="#F5BC25" fillOpacity="0.5"/>
                </g>
                <defs>
                    <filter id="filter0_b_282_11" x="-10" y="-10" width="220" height="220" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_282_11"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_282_11" result="shape"/>
                    </filter>
                </defs>
                </svg>
              </div>
              <div ref={page2imgRef} className="pagetwoimg">
                <YoutubeVideo videoId={videoUrl} width={width} height={height}/>
              </div>
            <div className="record1"></div>
            <div className="record2"></div>
          </div>
          </div>
          <div className="page22container">
          <div className="mikeimagecontainer">
            <img className="mike2" src={mike2} loading="lazy" alt="Neumann TLM 107"/>
            <div className="mic-text-container">
        <div className="lewitt"></div>
        <h1 className="miketext">Neumann <span>TLM 107</span></h1>
        </div>
        </div>
        
          <div ref={rect4Ref} className={`rect4 ${inView1 ? "animate" : ""}`}></div>        
        <div className="hero-text-two">
            <h1 className="herotext3">Embark on a creative <span>Journey</span></h1>

    <h1 className="subtext3">
        Melodium, where innovation fuels creativity. Our studio features state-of-the-art technology and infrastructure, catering to today's dynamic creative industries. Whether you're a musician recording your next chart-topper, a filmmaker scouting the perfect location, or a content creator seeking a professional space, our studio has everything you need.       
          </h1>
        </div>
        <div className="line-circle-divider2">
        <div className="rectangle42"></div>        
        <div className="circle33">
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
        <g filter="url(#filter0_b_576_7)">
            <ellipse cx="18.5" cy="19" rx="18.5" ry="19" fill="#F5BC25"/>
        </g>
        <defs>
            <filter id="filter0_b_576_7" x="-10" y="-10" width="57" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_576_7"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_576_7" result="shape"/>
            </filter>
        </defs>
        </svg>
        </div> 

        <div className="rectangle41"></div>
        </div>
        <div className="control-image-container">
        <div className="circlethree">
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
        <g filter="url(#filter0_b_282_11)">
            <circle cx="100" cy="100" r="100" fill="#F5BC25" fillOpacity="0.5"/>
        </g>
        <defs>
            <filter id="filter0_b_282_11" x="-10" y="-10" width="220" height="220" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_282_11"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_282_11" result="shape"/>
            </filter>
        </defs>
        </svg>
        </div>
          <div className="pagetwo2imagecontainer">
              <img className={`pagethreeimg ${inView1 ? "animate" : ""}`} src={pagethree} loading="lazy" alt="Control Room"/>   
          </div>
          <div className="control-text-container">
          <h1 className="control">Control <span>Room </span></h1>
          <div className="control1"></div>
          </div> 
        </div>
        <div className="melodiumcontainer">
        <h1 className="melodium">MEL<span>O</span>DIUM</h1>
         </div>
          </div>
        
        </div>
        </>


    );
}

export default Pagetwo;