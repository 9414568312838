import {useState,useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import "./MediaStyles.css";
import DashboardMenu from "../Mini/DashboardMenu";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function Media  (){
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [username,setUsername] = useState("")
  const API_BASE = process.env.REACT_APP_API_BASE;
  useEffect(() => {
    const verifyCookie = async () => {
      if(cookies.token==="undefined")
        {
          navigate("/login");
        }
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
       
        `${API_BASE}/verify`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      return status
        ? console.log("Booking")
        : (removeCookie("token"),navigate("/Dashboard/Login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  
  const updateVideoUrl = async (id,newVideoUrl) => {
    try {
      const response = await fetch(API_BASE+'/UpdateVideoUrl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ vidid: id, newVideoUrl })
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error:', errorMessage);
      }
      else
      toast("Video Updated", {
        position: "top-left",
        autoClose: 2000, 
        type: "success", 
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const updateImageUrl = async (id,newImageUrl) => {
    try {
      const response = await fetch(API_BASE+'/UpdateImageUrl', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imageid: id, newimageUrl:newImageUrl })
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Error:', errorMessage);
      }
      else
      toast(`Image ${id} updated`, {
        position: "top-left",
        autoClose: 2000,
        type: "success", 
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleImageFormSubmit = (e) => {
    e.preventDefault();
    const idInput = e.target.elements["image-id"];
    const linkInput = e.target.elements["image-link"];
  
    const id = idInput.value;
    const link = linkInput.value;
    updateImageUrl(id,link)
  
  };
  const handleFormSubmit = (e,Vid) => {
    e.preventDefault();
    let url = ''
    const id = Vid;
    url = e.target.elements[`video-url-${id}`].value;
    updateVideoUrl(id,url)
  };
  return (
    <>
    <div className="audio">
    <DashboardMenu/>
        <h1 className="text-wrapper">Media</h1>
        <div className="media-line"/>
        <div className="text-wrapper-1">Video</div>
        <div className="text-wrapper-2">Pictures</div>
        <form onSubmit={(e) => handleFormSubmit(e, 1)}>
          <input type="text" className="text-wrapper-10" name="video-url-1" placeholder="Enter video URL 1"/>
          <input type="submit" value="Confirm"  className="confirm-video-1"/>
        </form>
        <form onSubmit={(e) => handleFormSubmit(e, 2)}>
          <input type="text" className="text-wrapper-11" name="video-url-2" placeholder="Enter video URL 2"/>
          <input type="submit" value="Confirm"  className="confirm-video-2"/>
        </form>
        <form onSubmit={(e) => handleImageFormSubmit(e)}>
          <input type="text" className="text-wrapper-12" name="image-id" placeholder="Enter Image ID(1-7)"/>
          <input type="text" className="text-wrapper-13" name="image-link" placeholder="Enter Image Link"/>
          <input type="submit" value="Confirm"  className="confirm-video-3"/>
        </form>
        <ToastContainer/>
    </div>
    </>
  );
};
export default Media;

