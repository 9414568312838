import { useEffect, useState } from 'react';

const FontLoader = () => {
    const [initialFontsLoaded, setInitialFontsLoaded] = useState(false);
  
    useEffect(() => {
      const initialFonts = [
        new FontFace('Staat', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/u9qnesngn3awydgn4ea2.woff)'),
        new FontFace('Roboto', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Roboto.woff)'),
        new FontFace('Mont', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Mont.woff)'),
      ];
  
      Promise.all(initialFonts.map((font) => font.load()))
        .then((loadedFonts) => {
          loadedFonts.forEach((font) => {
            document.fonts.add(font);
          });
  
          setTimeout(() => {
            setInitialFontsLoaded(true);
          }, 2000);
        })
        .catch((error) => {
          console.error('Error loading initial fonts:', error.message);
        });
    }, []);
  
    useEffect(() => {
      if (initialFontsLoaded) {
        const remainingFonts = [
            new FontFace('Mate', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Mate.woff)'),
            new FontFace('bayon', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/bayon.woff)'),
            new FontFace('ava', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/ava.woff)'),
            new FontFace('Amar', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Amar.woff)'),
            new FontFace('LeagueGothic', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/League.woff)'),
            new FontFace('Moulpali', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Moul.woff)'),
            new FontFace('monda', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Monda.woff)'),
            new FontFace('baloo', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Balooda.woff)'),
            new FontFace('Karantina', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Karantina.woff)'),
            new FontFace('Maven', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Maven.woff)'),
            new FontFace('Morn', 'url(https://res.cloudinary.com/dif76m0mg/raw/upload/Morn.woff)'),
          ];
  
        Promise.all(remainingFonts.map((font) => font.load()))
          .then((loadedFonts) => {
            loadedFonts.forEach((font) => {
              document.fonts.add(font);
            });
          })
          .catch((error) => {
            console.error('Error loading remaining fonts:', error.message);
          });
      }
    }, [initialFontsLoaded]);
    return null;
  };
  
  
export default FontLoader;
