import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import './TimeLine.css'

const BarChartComponent = () => {
  const API_BASE =  process.env.REACT_APP_API_BASE;

  const initialBarData = [
    { name: "10:00 - 11:00am", value: 0 },
    { name: "11:00 - 12:00pm", value: 0 },
    { name: "12:00 - 1:00pm", value: 0 },
    { name: "1:00 - 2:00pm", value: 0 },
    { name: "2:00 - 3:00pm", value: 0 },
    { name: "3:00 - 4:00pm", value: 0 },
    { name: "4:00 - 5:00pm", value: 0 },
    { name: "5:00 - 6:00pm", value: 0 }
  ];

  const [barData, setBarData] = useState(initialBarData);


  const GetBookings = () => {
    let aBarndpoint = API_BASE + '/AllTimeSlots';
    fetch(aBarndpoint)
      .then(res => res.json())
      .then(data => {
        // Flatten the array by splitting each time range into individual time slots
        const flattenedData = data.flatMap(booking => {
          const category = booking.TimeSlots;
          return category.map(timeSlot => ({ TimeSlots: timeSlot }));
        });
  
        // Calculate counts for each category
        const counts = flattenedData.reduce((acc, booking) => {
          const category = booking.TimeSlots;
          if (acc[category]) {
            acc[category]++;
          } else {
            acc[category] = 1;
          }
          return acc;
        }, {});
  
        // Update the Bar data
        const updatedBarData = barData.map(item => ({
          ...item,
          value: counts[item.name] || 0
        }));
        setBarData(updatedBarData);
      })
      .catch((err) => console.error("Error: ", err));
  };
  

  useEffect(() => {
    // Call the function to fetch bookings
    GetBookings();
  }, []); // Empty dependency array means this effect runs once after initial render

  return (
    <div className="BarChartContainer">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={barData}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
