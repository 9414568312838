import React from 'react';
import YouTube from 'react-youtube';

const YoutubeVideo = ({ videoId, width, height }) => {
  const opts = {
    height: height,
    width: width,
    playerVars: {
      autoplay: 1,
      origin: window.location.origin,
      key: process.env.REACT_APP_API_KEY      , // Replace with your actual API key
    },
  };

  const onReady = (event) => {
    event.target.pauseVideo();
  };

  return <YouTube videoId={videoId} opts={opts} onReady={onReady} />;
};

export default YoutubeVideo;
