import React, { useEffect, useState,useRef } from "react";
import Slider from 'react-slick';
import YoutubePlaylist from "../Mini/YoutubePlaylist";
import "./Gallery.css";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import GoogleReviews from './GoogleReviews'

function Gallery() {
  const [inView1, setInView1] = useState(false);
  const [inView2, setInView2] = useState(false);
  const [centerIndex, setCenterIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const vidContainerRef = useRef(null);


  useEffect(() => {
    const handleScroll = () => {
      const g1 = document.querySelector(".gallhero").getBoundingClientRect();
      const container = document.querySelector(".gallerycontainer").getBoundingClientRect();
      if (!inView1 && g1.top <= window.innerHeight && g1.bottom >= 0) {
        setInView1(true);
      }
      if (!inView2 && container.top <= window.innerHeight && container.bottom >= 0) {
        setInView2(true);
      }
    };

    fetchPlaylistUrl();
    fetchImages();
  
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inView1,inView2]); 


  
  const apiBaseUrl = process.env.REACT_APP_API_BASE;
  const fetchImageUrl = async(imageId) => {
    return fetch(`${apiBaseUrl}/GetImage/${imageId}`)
      .then(response => response.json())
      .then(data => {
        if (data.ImageURL) {
            return data.ImageURL;
        } else {
          console.error(`No image URL found for Image ${imageId}.`);
          return null;
        }
      })
      .catch(error => {
        console.error(`Error fetching Image ${imageId} URL:`, error);
        return null;
      });
  };
  
  const [playlistUrl, setPlaylistUrl] = useState('');
  const fetchPlaylistUrl = () => {
    fetch(`${apiBaseUrl}/GetVideo/2`)
      .then(response => response.json())
      .then(async (data) => {
        if (data.videoURL) {
          setPlaylistUrl(data.videoURL);
        } else {
          console.error('No playlist URL found.');
        }
      })
      .catch(error => console.error('Error fetching playlist URL:', error));

  };

  const extractVideoIdFromPlaylist = (url) => {
    const match = url.match(/[?&]list=([^?&]+)/);
    if (match && match[1]) {
      const playlistId = match[1];
      return `${playlistId}`;
    } else {
      return null;
    }
  };
  const videoIdFromPlaylist = extractVideoIdFromPlaylist(playlistUrl);
  const fetchImages = () => { 
    const imageUrlsArray = []; 
    for (let i = 1; i <= 7; i++) {
      fetchImageUrl(i)
        .then((imageUrl) => {
          imageUrlsArray.push(imageUrl);
  
          // Check if all images have been fetched
          if (imageUrlsArray.length === 7) {
            // All images have been fetched, update the state
            setImageUrls(imageUrlsArray);
  
            // Trigger a re-render by updating a state variable
            setImagesLoaded(true);
          }
        })
        .catch((error) => {
          console.error(`Error fetching Image ${i} URL:`, error);
        });
    }
  };
  const [imagesLoaded, setImagesLoaded] = useState(false);
  
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        beforeChange: (current, next) => setCenterIndex(next),
      };

  return (
    <>
      <div className="gallerycontainer">
      <div className="sound-cloud-widget">
          <div className="sound-cloud-widget">
            <iframe
              width="100%"
              height="100%"
              scrolling="no"
              frameBorder="no"
              title="SoundCloud Player"
              src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1783626030&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2 }}
            ></iframe>
            
          </div>
        {/* )} */}
      </div>
        <div  className="video-container">
        <div ref={vidContainerRef} className="melvideo">
            {videoIdFromPlaylist && (
              <YoutubePlaylist playlistId={videoIdFromPlaylist}/>
            )}
          </div>
          <div className="gallery1rect"></div>
          <div className="gallery2rect"></div>
          <div className="circlegallery">
          <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
              <g filter="url(#filter0_b_715_26)">
                <circle cx="30" cy="30" r="30" fill="url(#paint0_linear_715_26)"/>
              </g>
              <defs>
                <filter id="filter0_b_715_26" x="-10" y="-10" width="80" height="80" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                  <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImageFix" stdDeviation="5"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_715_26"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_715_26" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_715_26" x1="95.5" y1="-28" x2="-43.5" y2="123.5" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#F5BC25"/>
                  <stop offset="0.585871" stopColor="#F5BC25" stopOpacity="0"/>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="galleryherotext">
          <h1 className={`gallhero ${inView1 ? "animate" : ""}`}>
            <span>G</span>
            <span>A</span>
            <span>L</span>
            <span>L</span>
            <span>E</span>
            <span>R</span>
            <span>Y</span>
          </h1>
        </div>
        <Slider {...settings} className="carousel-container">
      <div className={`carousel-item-1 ${centerIndex === 0 ? 'center' : ''} ${centerIndex === 1 || centerIndex === 6 ? 'side' : ''} ${centerIndex === 5 ? 'right-corner' : ''}`}>
        <img src={imageUrls[0]} alt="Gallery 1" className="gall3"/>
      </div>
      <div className={`carousel-item-2 ${centerIndex === 1 ? 'center' : ''} ${centerIndex === 2 || centerIndex === 0 ? 'side' : ''} ${centerIndex === 6 ? 'right-corner' : ''}`}>
        <img src={imageUrls[1]} alt="Gallery 1" className="gall3" />
      </div>
      <div className={`carousel-item-3 ${centerIndex === 2 ? 'center' : ''} ${centerIndex === 3 || centerIndex === 1 ? 'side' : ''} ${centerIndex === 0 ? 'right-corner' : ''}`}>
        <img src={imageUrls[2]} alt="Gallery 1" className="gall3" />
      </div>
      <div className={`carousel-item-4 ${centerIndex === 3 ? 'center' : ''} ${centerIndex === 4 || centerIndex === 2 ? 'side' : ''} ${centerIndex === 1 ? 'right-corner' : ''}`}>
        <img src={imageUrls[3]} alt="Gallery 1" className="gall3" />
      </div>
      <div className={`carousel-item-5 ${centerIndex === 4 ? 'center' : ''} ${centerIndex === 5 || centerIndex === 3 ? 'side' : ''} ${centerIndex === 2 ? 'right-corner' : ''}`}>
        <img src={imageUrls[4]} alt="Gallery 1" className="gall3" />
      </div>
      <div className={`carousel-item-6 ${centerIndex === 5 ? 'center' : ''} ${centerIndex === 6 || centerIndex === 4 ? 'side' : ''} ${centerIndex === 3 ? 'right-corner' : ''}`}>
        <img src={imageUrls[5]} alt="Gallery 1" className="gall3" />
      </div>
      <div className={`carousel-item-7 ${centerIndex === 6 ? 'center' : ''} ${centerIndex === 0 || centerIndex === 5 ? 'side' : ''} ${centerIndex === 4 ? 'right-corner' : ''}`}>
        <img src={imageUrls[6]} alt="Gallery 1" className="gall3" />
      </div>
    </Slider>
        <div className="pageReviewcontainer">
            <h1 className="testi">Testimonials</h1>
                <GoogleReviews/>
        </div>
        
      </div>
    </>
  );
}

export default Gallery;