import {useState,useEffect} from 'react'
import './PaymentUpdate.css'
import DashboardMenuPayment from '../Mini/DashMenuPayment';
const API_BASE = 'http://localhost:3001'

function DashboardBooking() {
    const [BookingDetails, setBookingDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [showTable, setShowTable] = useState(false);
    var d = new Date();
    var currentDate;
    useEffect(() => {

            GetBookings();
    }, []);

   
    const GetBookings = () => {
        let apiEndpoint = API_BASE + '/BookingDisplay';
        fetch(apiEndpoint)
            .then(res => res.json())
            .then(data => {
                data.sort((a, b) => new Date(b.DateBooked) - new Date(a.DateBooked));
                
                setBookingDetails(data);
                setShowTable(true)
            })
            .catch((err) => console.error("Error: ", err));
    };

    const getUpcomingBookings = (date,Type) => {
        let apiEndpoint = API_BASE + '/BookingTimeSlots/' + date +'/2/'+Type;
        
        fetch(apiEndpoint)
            .then(res => res.json())
            .then(data => {
                setBookingDetails(data);
            })
            .catch((err) => console.error("Error: ", err));
      };

    const GetBookingsSearch = (fn, ln) => {
        let apiEndpoint = API_BASE + '/BookingInfo/' + fn + '/' + ln;
        fetch(apiEndpoint)
            .then(res => res.json())
            .then(data => {
              data.sort((a, b) => new Date(b.DateBooked) - new Date(a.DateBooked));
              
              setBookingDetails(data);
          })
          .catch((err) => console.error("Error: ", err));
    };  
    const BookingsToday = () =>
    {
            currentDate = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
            getUpcomingBookings(currentDate,'All');
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const [firstname, lastname] = searchTerm.split(' ');
        GetBookingsSearch(firstname, lastname);
    };
    const getTypeBookings = (Type) =>
    {
        getUpcomingBookings(currentDate,Type);
    }

    const HandleEditBooking = (booking) =>
    {
        updatePayment(booking.BookingID)
        GetBookings()
    }


  const updatePayment = async (bookingID) => {
    const apiEndpoint = API_BASE + '/UpdatePayment/' + bookingID; 
    try {
      const response = await fetch(apiEndpoint, {
        method: 'PUT', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ PaymentStatus: 'Paid' }), 
      });
  
      if (response.ok) {
        return response.json(); 
      } else {
        throw new Error(`Failed to update booking. Server returned status ${response.status}. Response: ${await response.text()}`);
      }
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
        <div className="pbookingcont">
               <div className="PBookingContainer">
               {}
               <DashboardMenuPayment/>
                    <div className="PUtilityBar">
                        <form onSubmit={handleFormSubmit}>
                            <input
                                type="text"
                                id="Psearch-bar"
                                name="IDSearch"
                                placeholder='Enter Name'
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                            <input type="submit" value="Search" id='Psearch-button' />
                        </form>
                        <button className='PStudioButtonD' onClick={()=>getTypeBookings("Studio")}>
                        Studio
                      </button>
                              <button className='PJamRoomButtonD' onClick={()=>getTypeBookings("Jam Room")}>
                                  Jam Room
                              </button>
                        <div className="Psorting-booking-buttons">
                            <button id="Ptoday-booking-button" onClick={BookingsToday}>Today</button>
                            <button id="Preset-button" onClick={GetBookings}>Reset</button>
                            <input
                                type="date"
                                name="BookingDate"
                                id="PBookingDateSelect"
                                placeholder='Date'
                                value={selectedDate}
                                onChange={e => setSelectedDate(e.target.value)}
                            />
                        </div>
                  </div>
                    <div className="Ptable-scroll">
          {BookingDetails.length === 0 ? (
            <h1>No available record</h1>
          ) : (
            <div>
              
                <table>
                {showTable &&(
                  <>
                  <thead className="PHeaderTitles">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Ph. No.</th>
                      <th>Service</th>
                      <th>Timings</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {BookingDetails.map(booking => (
                      <tr key={booking.id}>
                        <td>{booking.BookingID}</td>
                        <td>{booking.FirstName} {booking.LastName}</td>
                        <td>{booking.EmailAddress}</td>
                        <td>{booking.ContactNumber}</td>
                        <td>{booking.TypeOfSession}</td>
                        <td>{booking.TimeSlots}</td>
                        <td>{booking.BookingDate}</td>
                        <td>{booking.PaymentStatus}</td>
                        <td>
                          <button id='Pedit-button' onClick={() => HandleEditBooking(booking)}>Update Payment</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  </>
                )}
                </table>
              
            </div>
          )}
        </div>
                </div>
                </div>
    </>
  )
}

export default DashboardBooking