import React, { useState,useEffect } from 'react';
import './BookingOne.css'
import BookingStage from '../Mini/BookingStage'
import { Icon } from '@iconify/react';
import {Link,  useNavigate, Navigate, useLocation} from 'react-router-dom';
const API_BASE = process.env.REACT_APP_API_BASE;

function getDate() {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  var dates = [];

  for (let i = 0; i < 7; i++) {
      var d = new Date();
      d.setDate(d.getDate() + i);
      var month = d.toLocaleString('default', { month: 'long' });
      var year = d.getFullYear();
      var date = d.getDate();
      var day = daysOfWeek[d.getDay()];
      var MonthShort = month.substr(0, 3);
      if(d.getDay()!==0)
        dates.push({ month, year, date, MonthShort, day });
  }
  return dates;
}
function getDays() {
  const realDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  let currentDayIndex = today.getDay(); 
  if (currentDayIndex === 0) 
  {
    currentDayIndex = 1;
  }
  const days = [];
  for (let i = 0; i < 6; i++) { // Loop from Monday to Saturday
    const dayIndex = (currentDayIndex + i) % 7; // Get the day index (0 for Monday, 1 for Tuesday, etc.)
    days.push(realDays[dayIndex]);
  }
  return days;
}
function BookingOne({setHasCompletedTimeSlots}) {
  const Times = [
    "10:00 - 11:00am",
    "11:00 - 12:00pm",
    "12:00 - 1:00pm",
    "1:00 - 2:00pm",
    "2:00 - 3:00pm",
    "3:00 - 4:00pm",
    "4:00 - 5:00pm",
    "5:00 - 6:00pm",
  ];
  const navigate = useNavigate();
  var d = new Date();
  const weekDates = getDate();
  const [U,setU] = useState(Times);
  const [clickedIndex, setClickedIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState(weekDates[0].date); 
  const [selectedMon, setSelectedMon] = useState(weekDates[0].month);
  const [selectedYear, setSelectedYear] = useState(weekDates[0].year);
  const [day, setDay] = useState("Monday");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  var selectedDay = "Monday"
  const location = useLocation();
  const buttonType = location.state.Type;

  useEffect(() => {
    handleClick(0);
  }, []);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleTimeSlotClick = (timeRange) => {
    setSelectedTimeSlots(prevSelectedSlots => {
      const updatedSlots = prevSelectedSlots.includes(timeRange)
        ? prevSelectedSlots.filter(slot => slot !== timeRange)
        : [...prevSelectedSlots, timeRange];
      return updatedSlots;
     
    });
  };
  
  const bookingDate = (Day,Month,Year) =>
  {
    const monthIndex = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].indexOf(Month)+1;
    return Day + '-' + monthIndex + '-' + Year;
  }
  const getAvailableTimeSlots = async(Day, Month, Year,Type) => {
    const date = bookingDate(Day, Month, Year);
    let apiEndpoint = API_BASE + '/BookingTimeSlots/' + date +'/1/'+Type;
    
    return fetch(apiEndpoint) 
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(data => {
        if (data === null) {
          return []; // Return an empty array
        } else if (Array.isArray(data)) {
          return data; // Return the array of time slots
        } else {
          return []; // Return an empty array
        }
      })
      .catch(error => {
        return []; // Return an empty array
      });
  };
  const lockedSlots = async(Day, Month, Year,Type) =>
  {

    const date = bookingDate(Day, Month, Year);
    let apiEndpoint = API_BASE + '/getLocked/'+date
    return fetch(apiEndpoint)
    .then(res=>{
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .then(data => {
      if (data.timeSlots === null) {
        return []; // Return an empty array
      } else if (Array.isArray(data)) {
        return data; // Return the array of time slots
      } else {
        return undefined; // Return an empty array
      }
    })
    .catch(error => {
      return []; // Return an empty array
    });
  }

  const handleClick = async (index) => {
    setSelectedTimeSlots([])
    try {
      setClickedIndex(index);
      setSelectedDate(weekDates[index].date);
      setSelectedMon(weekDates[index].month);
      setSelectedYear(weekDates[index].year); 
      const dayss = getDays();
      setDay(dayss[index]);
      const currentDateStr = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
      const selectedDateStr = bookingDate(weekDates[index].date,weekDates[index].month,weekDates[index].year);
      if (currentDateStr === selectedDateStr) {
        var currentHour = d.getHours();
        const currentMinute = d.getMinutes();
        const currentTimeIndex = Math.floor(currentHour - 10)  + (currentMinute >= 1 ? 1 : 0);
        const availableTimes = await getAvailableTimeSlots(
          weekDates[index].date,
          weekDates[index].month,
          weekDates[index].year,
          buttonType
        );
  
        if (availableTimes === undefined) {
        } else {
          const filteredTimes = Times.slice(currentTimeIndex).filter(time => !availableTimes.includes(time));
          setU(filteredTimes);
        }
  
        setSelectedTimeSlots([]);
      } else {

        var availableTimes = await getAvailableTimeSlots(weekDates[index].date, weekDates[index].month, weekDates[index].year,buttonType);
        var lockedSlot = await lockedSlots(weekDates[index].date, weekDates[index].month, weekDates[index].year);
            if (availableTimes === undefined) {
            } else {
              var UniqueTimes = Times.filter(time => !availableTimes.includes(time));
              if (Array.isArray(lockedSlot)) {
                UniqueTimes = UniqueTimes.filter(time => !lockedSlot.includes(time));
              }
              setU(UniqueTimes)
            }
      }
    } catch (error) {
    }
    
  };
 

  const handleStepCompletion = () => {
    setHasCompletedTimeSlots(true);
  }
  const handleFormSubmit = (event) => {
    event.preventDefault();
    const date = bookingDate(selectedDate, selectedMon, selectedYear)
    const selectedInfo = {
      date,
      selectedTimeSlots,
      day
    };
   
    if (selectedTimeSlots.length !== 0) {
        addlockedSlot(selectedInfo.selectedTimeSlots,date);
      handleStepCompletion();
      navigate("/BookingDetails", { state: { DateAndTime: selectedInfo,Type:buttonType } });
    }
  };
  const addlockedSlot = async (timeslot,date) => {
    try {
      await fetch(API_BASE + '/SlotLocked', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          TimeSlots: timeslot,
          BookingDate: date
        })
      }).then(res => res.json());
    } catch (error) {
      // Handle the error here
      console.error("Error:", error);
    }
  }
  const handleBack =() =>
  {
    navigate('/')
  }
  return (
    <>
    <div className="BookingOneContainer">
    
        <form onSubmit={handleFormSubmit}>
          <BookingStage stage={1}/>
          <Icon icon="material-symbols:arrow-back" id='back-arrow' onClick={handleBack}/>
          <h1 className='MMYY'>{weekDates[0].month} {weekDates[0].year}</h1>
          <div className='FDates'>
            <div className='DatesS'>
                {weekDates.map((dayInfo, index) => (
                  <div
                  key={index}
                  className={index === clickedIndex ? 'SDayDate' : 'DayDate'}
                  onClick={() => handleClick(index)}
                >
                    <h1 className='Day'>{dayInfo.day}</h1>
                    <h1 className='Date'>{dayInfo.MonthShort} {dayInfo.date}</h1>
                  </div>
                ))}
            </div>
          </div>
          <div className='Header'>
            <h1 className='SelDate'>
            {selectedDate} {selectedMon} {selectedYear}
            </h1>
          </div>
          <div className='DispTimeSlots'>
          {U.length > 0 ? (
            U.map((timeRange, index) => (
              <input
                key={index}
                type="button"
                className={`TimeS ${selectedTimeSlots.includes(timeRange) ? 'selected' : ''}`}
                value={timeRange}
                onClick={() => handleTimeSlotClick(timeRange)}
              />
            ))
          ) : (
            <p>No available time slots.</p>
          )}
        </div>
          <input type="Submit" value="Proceed" className='ProButton'/>
        </form>
      </div>
    </>
  );
}
export default BookingOne