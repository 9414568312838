import React,{useState,useEffect} from 'react'
import { useCookies } from "react-cookie";
import axios from "axios";
import './Analytics.css'
import DashboardMenu from '../Mini/DashboardMenu'
import PieRechartComponent from '../Mini/PieChartDash'
import BarChartComponent from '../Mini/TimeLineChart'
// import DayChart from '../Mini/DayChart'
import DayRechartComponent from '../Mini/DayChart'
import { useNavigate } from 'react-router-dom';
function Analytics() {
  const apiBase = process.env.REACT_APP_API_BASE
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
       
        `${apiBase}/verify`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      return status
        ? console.log("Booking")
        : (removeCookie("token"),navigate("/Dashboard/Login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);
  return (
    <>
    <div className="AnalyticsContainer">
        <DashboardMenu/>
        <h1 className="text-wrapper-analytics">Analytics</h1>
        <div className="analytics-line"/>
        <PieRechartComponent/>
        {/* <DayChart/> */}
        <DayRechartComponent/>
        <BarChartComponent/>
    </div>
    </>
  )
}

export default Analytics