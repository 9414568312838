import React from 'react'
import '../Mini/TimeSlots.css'

export const TimeSlots = ({ SlotValue }) => {
  return (
    <>
      <div
        className="Slott" style={{color:'black',background:'#D9D9D9'}}
      >
        {SlotValue}
      </div>
    </>
  );
};

export default TimeSlots;
