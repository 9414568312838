import React, { useEffect } from 'react';
import './YoutubePlaylist.css'

const YoutubePlaylist = ({ playlistId}) => {
  useEffect(() => {
    loadYoutubeAPI();
  }, [playlistId]);

  const loadYoutubeAPI = () => {
    const script = document.createElement('script');
    script.src = 'https://www.youtube.com/iframe_api';

    script.onload = () => {
      createPlayer();
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  };

  const createPlayer = () => {
    if (window.YT && window.YT.Player) {
      new window.YT.Player('yt-player', {
        playerVars: {
          listType: 'playlist',
          list: playlistId,
        },
      });
    } else {
      setTimeout(() => createPlayer(), 100);
    }
  };

  return <div id="yt-player"/>;
};

export default YoutubePlaylist;
