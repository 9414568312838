import React,{useEffect, useState} from "react";
import "./Pagefour.css"

function Pagefour(){
    const [inView1, setInView1] = useState(false);

    const pagefourbg = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/osk64dmqxehzm5xd4zlo`;
    const studio1 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/vtgfaxae663ao9mr5gn4`
    const studio2 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/gbvtrmcvxxusahkydvmf`
    const studio3 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/cqg8xoxl31dnpvo1tki9`
    const studio5 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/vbbepwe43dlfu8pjkmw0`
    const studio6 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/ik8bpqk3iaafoaqup1kr`
    const studio8 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/vzr8n2shbix6xbqsilzb`
    const studio9 =    `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/x73bvkrknp1fonqdy3ty`

    useEffect(() => {
      const handleScroll = () => {
        const st1 = document.querySelector(".studiorow1").getBoundingClientRect();
        const st2 = document.querySelector(".studiorow2").getBoundingClientRect();
        const st3 = document.querySelector(".studiorow3").getBoundingClientRect();
        const st4 = document.querySelector(".studiorow5").getBoundingClientRect();
        const st5 = document.querySelector(".studiorow6").getBoundingClientRect();
        const st6 = document.querySelector(".studiorow8").getBoundingClientRect();
        const st7 = document.querySelector(".studiorow9").getBoundingClientRect();
  
        if (!inView1 && (st1.top <= window.innerHeight && st1.bottom >= 0)) {
            setInView1(true);
          }
          if (!inView1 && (st2.top <= window.innerHeight && st2.bottom >= 0)) {
            setInView1(true);
          }
          if (!inView1 && (st3.top <= window.innerHeight && st3.bottom >= 0)) {
            setInView1(true);
          }
          if (!inView1 && (st4.top <= window.innerHeight && st4.bottom >= 0)) {
            setInView1(true);
          }
          if (!inView1 && (st5.top <= window.innerHeight && st5.bottom >= 0)) {
            setInView1(true);
          }
          if (!inView1 && (st6.top <= window.innerHeight && st6.bottom >= 0)) {
            setInView1(true);
          }
          if (!inView1 && (st7.top <= window.innerHeight && st7.bottom >= 0)) {
            setInView1(true);
          }
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [inView1]);

    return (
        <>
        <div className="pagefourcontainer">
            <div className="imgcontainer">
                <img className="pagefourbg" src={pagefourbg} loading="lazy" alt="Page Four Background"/> 
            </div>
            <h1 className="titlestudio">THE STUDIO⚡</h1>
            <div className="row-one">
            <div  className={`studiorow1 ${inView1 ? "animate" : ""}`}>
                    <img src={studio1} loading="lazy" alt="Recording Room"/>
                    <div className="recordingstudio">
                        <h1>Recording Room</h1>
                    </div>
                </div>

                <div className={`studiorow2 ${inView1 ? "animate" : ""}`}>
                    <img src={studio5} loading="lazy" alt="Neumann's KM 184 Stereo Pair"/>
                    <div className="pencilmike">
                        <h1>Neumann's KM 184 Stereo Pair</h1>
                    </div>
                    
                </div>

                <div className={`studiorow3 ${inView1 ? "animate" : ""}`}>
                    <img src={studio3} loading="lazy" alt="Qsc Touch Mix Pro"/>
                    <div className="mixertext">
                    <h1>Qsc Touch Mix Pro</h1>
                    </div>
                </div>

                <div className={`studiorow4 ${inView1 ? "animate" : ""}`}>
                    <img src={studio2} loading="lazy" alt="SHURE Beta 58A Pair"/>
                    <div className="samemike">
                        <h1>SHURE Beta 58A Pair</h1>
                    </div>
                </div>
            </div>
            
              <div className="row-two">
                  
                <div className={`studiorow5 ${inView1 ? "animate" : ""}`}>
                    <img src={studio2} loading="lazy" alt="SHURE Beta 58A Pair"/>
                </div>

                <div className={`studiorow6 ${inView1 ? "animate" : ""}`}>
                    <img src={studio8} loading="lazy" alt="Control Room"/>
                    <div className="liveroomtext">
                        <h1>Control Room</h1>
                    </div>
                </div>

                <div className={`studiorow7 ${inView1 ? "animate" : ""}`}>
                     <img src={studio6} loading="lazy" alt="Mics"/>
                    <div className="allmikes">
                        <h1>Mics</h1>
                    </div>
                </div>

                 <div className={`studiorow8 ${inView1 ? "animate" : ""}`}>
                     <img src={studio9} loading="lazy" alt="Lounge"/>
                     <div className="loungeroom">
                        <h1>Lounge</h1>
                    </div>
                 </div> 

                <div className={`studiorow9 ${inView1 ? "animate" : ""}`}>
                     <img src={studio1} loading="lazy" alt="Recording Room"/>
                </div>  
                
                

              </div>                
            </div>                      
         
        </>
    );
}
export default Pagefour;