import React,{useState,useEffect} from 'react'
import { useCookies } from "react-cookie";
import axios from "axios";
import './DashboardMenu.css'
import { Icon } from '@iconify/react';
import Logo from '../../Images/MelodiumLogo.webp'
import {Link, useNavigate} from 'react-router-dom';
function DashboardMenu() {
  const apiBase = process.env.REACT_APP_API_BASE
  const navigate = useNavigate()
  const [cookies, removeCookie] = useCookies([]);
  const [username,setUsername] = useState("")
  useEffect(() => {
    const verifyCookie = async () => {
      if(cookies.token==="undefined")
        {
          navigate("/login");
        }
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
        `${apiBase}/verify`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      return status
        ? console.log("Booking")
        : (removeCookie("token"),navigate("/Dashboard/Login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);
  const handleLogout = async () => {
    try {
      console.log(cookies);
      await removeCookie("token");
      navigate("/Dashboard/Login");

    } catch (error) {
      console.error("Error removing token:", error);
      // Handle error if needed
    }
  };
  const handleHomeNavigate = () =>
  {
    navigate("/")
  }
  
  return (
    <>
        <div className="dashboard-menu">
          <div className="studio-image-text-cont" onClick={handleHomeNavigate}>
                <img className="menu-logo" alt="Rectangle" src={Logo} />
                <span className="spanMel">Melodium</span>
          </div>
          <div className="dash-menu-line1"/>
          <div className='dashboard-menu-container'>
            <ul>
              <li>
              <Link to="/Dashboard/AddUser">
                  <div className="icon-text">
                    <Icon icon="streamline:user-add-plus-solid" color="#f5bc25" />  Add User
                  </div>
              </Link>
              </li>
              <li>
                  <Link to="/Dashboard/Analytics">
                    <div className="icon-text">
                    <Icon icon="carbon:analytics" color="#f5bc25" /> Analytics
                    </div>
                  </Link>
              </li>
              <li> <Link to="/Dashboard/Booking">
                      <div className="icon-text">
                        <Icon icon="ion:calendar" color="#f5bc25" /> Bookings
                      </div>
                    </Link>
              </li>
              <li>
                  <Link to="/Dashboard/Media">
                    <div className="icon-text"> 
                      <Icon icon="material-symbols:perm-media" color="#f5bc25" /> Media
                    </div>
                  </Link>
              </li>
            </ul>
          </div>
          <div className="dash-menu-line2"/>
          <div className='dashboard-menu-container2'>
            <ul>
            <li>  <Link to="/Dashboard/Security">
                    <div className="icon-text">
                      <Icon icon="wpf:key-security" color="#f5bc25" /> Security
                    </div>
                    </Link>
              </li>
              <li>
                  <div className="icon-text" onClick={handleLogout}> 
                    <Icon icon="icon-park-outline:logout" color="#f5bc25" /> Logout
                  </div>
              </li>
            </ul>
          </div>
              
        </div>
    </>
  )
}

export default DashboardMenu