import React,{useEffect, useState,useLayoutEffect} from "react";
import "./Pagefive.css"
import Slider from "react-slick";

function Pagefive()
{
    const fac1 = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/rt1uktxqadhfcghonwoc`
    const fac2 = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/ggcr0gwi3kqbunwxgvpy`
    const fac3 = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/ps1el19rmpmnffs38ybh`
    const fac4 = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/r5nat9nsyubeichif1ti`
    const fac5 = `https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/wkpqllxz2aid4948kpav`
    const [inView1, setInView1] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    useLayoutEffect(() => {
      const handleResize = () => {
        checkIfMobile();
        checkIfMobile();
      };
  
      checkIfMobile(); 
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    const handleSliderArrowClick = () => {
      setClicked(false);

    };
    const [importTimer, setimportTimer] = useState(false);
    useEffect(() => {
      const timer = setTimeout(() => {
        setimportTimer(true);
      }, 200); 
      return () => clearTimeout(timer); 
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const fhtElement = document.querySelector(".facilityhero");
            const fac5Element = document.querySelector(".fac5");
            const fac4Element = document.querySelector(".fac4");
            const fac3Element = document.querySelector(".fac3");
            const fac2Element = document.querySelector(".fac2");
            const fac1Element = document.querySelector(".fac1");
          
            const fht = fhtElement ? fhtElement.getBoundingClientRect() : null;
            const fac5 = fac5Element ? fac5Element.getBoundingClientRect() : null;
            const fac4 = fac4Element ? fac4Element.getBoundingClientRect() : null;
            const fac3 = fac3Element ? fac3Element.getBoundingClientRect() : null;
            const fac2 = fac2Element ? fac2Element.getBoundingClientRect() : null;
            const fac1 = fac1Element ? fac1Element.getBoundingClientRect() : null;
          
            if (!inView1 && fht && (fht.top <= window.innerHeight && fht.bottom >= 0)) {
              setInView1(true);
            }
            if (!inView1 && fac5 && (fac5.top <= window.innerHeight && fac5.bottom >= 0)) {
              setInView1(true);
            }
            if (!inView1 && fac4 && (fac4.top <= window.innerHeight && fac4.bottom >= 0)) {
              setInView1(true);
            }
            if (!inView1 && fac3 && (fac3.top <= window.innerHeight && fac3.bottom >= 0)) {
              setInView1(true);
            }
            if (!inView1 && fac2 && (fac2.top <= window.innerHeight && fac2.bottom >= 0)) {
              setInView1(true);
            }
            if (!inView1 && fac1 && (fac1.top <= window.innerHeight && fac1.bottom >= 0)) {
              setInView1(true);
            }
          };
          if(importTimer)
          {
            import ('slick-carousel/slick/slick.css');
            import ('slick-carousel/slick/slick-theme.css');
          }
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [inView1]);

    const [isActive, setIsActive] = useState(false);
    const [isH1Displayed, setIsH1Displayed] = useState(false); // New state variable
    const [isActive2, setIsActive2] = useState(false);
    const [isH1Displayed2, setIsH1Displayed2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isH1Displayed3, setIsH1Displayed3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [isH1Displayed4, setIsH1Displayed4] = useState(false);
    const [isActive5, setIsActive5] = useState(false);
    const [isH1Displayed5, setIsH1Displayed5] = useState(false);
    

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: ()=>handleSliderArrowClick(),
      };
      
  
    const CardInfo = (facility) => {
      setTimeout(() => {
        if(facility==='facility1')
        {
            setIsActive(true);
            setIsH1Displayed(!isH1Displayed);
        }
        else
        if(facility==='facility2')
        {
            setIsActive2(true);
            setIsH1Displayed2(!isH1Displayed2);
        }
        if(facility==='facility3')
        {
            setIsActive3(true);
            setIsH1Displayed3(!isH1Displayed3);
        }
        if(facility==='facility4')
        {
            setIsActive4(true);
            setIsH1Displayed4(!isH1Displayed3);
        }
        if(facility==='facility5')
        {
            setIsActive5(true);
            setIsH1Displayed5(!isH1Displayed3);
        } // Toggle the state variable
      }, 100);
    }
  
    const handleMouseLeave = () => {
      setIsActive(false);
      setIsH1Displayed(false); // Reset the state variable when leaving
      setIsActive2(false);
      setIsH1Displayed2(false);
      setIsActive3(false);
      setIsH1Displayed3(false);
      setIsActive4(false);
      setIsH1Displayed4(false);
      setIsActive5(false);
      setIsH1Displayed5(false);
    }
    const displaySubtext = () =>
    {
        setClicked(!clicked);

    }
    return (
        <>
        <div className="pagefivecontainer">
          {!isMobile && <div className="facilitycircle" />}
               <div className={`facilityherotext ${inView1 ? "animate" : ""}`}>
                < h1 className="facilityhero" >

                        Fuel creativity in our cutting-edge studio facilities
                    </h1>
                </div>
                <div className="facilitysubcontainer">
                    <div className="facilitysub">
                        <h1 className="facilitysubtext">Dive into a world of possibilities with our premium studio facilities, meticulously designed to enhance every aspect of your creative process</h1>
                    </div>
                </div>
          <div className="all5images">
          {isMobile ? (
              <Slider {...settings} onNextArrowClick={handleSliderArrowClick} onPrevArrowClick={handleSliderArrowClick}>
              <div className="facility-image-container-1" 
                onClick={()=>displaySubtext()}
              >
                <h1 className="facility-text-1">Control Room</h1>
                <h3
                  className={`facility-subtext-1 ${clicked ? "displayed" : ""}`}
                  style={{ display: clicked ? "block" : "none" }}
                >
                  Step into our state-of-the-art audio room, boasting impeccable acoustics crafted by India's leading experts. It's where audio mixing and mastering reach their peak performance.
                </h3>
                <img
                  className="facility-image-1"
                  // style={{ filter: clicked ? 'blur(5px)' : 'none' }}
                  src={fac1}
                  alt="Control Room"
                  
                />
              </div>
              <div
                className="facility-image-container-2"
                onClick={()=>displaySubtext()}
              >
                <h1 className="facility-text-2">Jam Room</h1>
                <h3
                  className={`facility-subtext-2 ${clicked ? "displayed" : ""}`}
                  style={{ display: clicked ? "block" : "none" }}
                >
                  Experience the ultimate soundproofed and climate-controlled
                  rehearsal space, tailor-made for bands to perfect their music,
                  ensuring every note is on point before hitting the recording
                  studio or taking the stage.
                </h3>
                <img
                  className="facility-image-2"
                  src={fac2}
                  alt="Control Room"
                />
              </div>
              <div
                className="facility-image-container-3"
                onClick={()=>displaySubtext()}
              >
                <h1 className="facility-text-3">Recording Room A</h1>
                <h3
                  className={`facility-subtext-3 ${clicked ? "displayed" : ""}`}
                  style={{ display: clicked ? "block" : "none" }}
                >
                  This room is optimized for recording vocals and acoustic
                  instruments, offering superior sound quality for capturing
                  drums, choirs, and symphonies. Comprehensive acoustic treatment
                  extends to the ceiling to eliminate standing waves and enhance
                  audio clarity.
                </h3>
                <img
                  className="facility-image-3"
                  src={fac3}
                  alt="Recording Room A"
                />
              </div>
              <div
                className="facility-image-container-4"
                onClick={()=>displaySubtext()}
              >
                <h1 className="facility-text-4">Equipment</h1>
                <h3
                  className={`facility-subtext-4 ${clicked ? "displayed" : ""}`}
                  style={{ display: clicked ? "block" : "none" }}
                >
                  Elevate your sound with premium gear, featuring AKG, Neumann, Kali, Yamaha, Behringer, and more, for unmatched mixing and mastering excellence.
                  </h3>
                <img
                  className="facility-image-4"
                  src={fac4}
                  alt="Equipment"
                />
              </div>
              <div
                className="facility-image-container-5"
                onClick={()=>displaySubtext()}
              >
                <h1 className="facility-text-5">Lounge</h1>
                <h3
                  className={`facility-subtext-5 ${clicked ? "displayed" : ""}`}
                  style={{ display: clicked ? "block" : "none" }}
                >
                  Exciting lounge coming soon!
                </h3>
                <img
                  className="facility-image-5"
                  src={fac5}
                  alt="Equipment"
                />
              </div>
            </Slider>
          ) : (
            <>
              <div
                className={`fac1container  ${isActive ? "active" : ""}`}
                onClick={() => CardInfo("facility1")}
                onMouseLeave={handleMouseLeave}
              >
                <h1>Control Room</h1>
                <h3 className={`fac1subtext ${isH1Displayed ? "displayed" : ""}`}>
                  Step into our state-of-the-art audio room, boasting impeccable
                  acoustics crafted by India's leading experts. It's where audio
                  mixing and mastering reach their peak performance.
                </h3>
                <img
                  src={fac1}
                  alt="Control Room"
                  className={`fac1 ${inView1 ? "animate" : ""}`}
                />
              </div>

              <div
                className={`fac2container  ${isActive2 ? "active2" : ""}`}
                onClick={() => CardInfo("facility2")}
                onMouseLeave={handleMouseLeave}
              >
                <h1>Jam Room</h1>
                <h3 className={`fac2subtext ${isH1Displayed2 ? "displayed2" : ""}`}>
                  Experience the ultimate soundproofed and climate-controlled
                  rehearsal space, tailor-made for bands to perfect their music,
                  ensuring every note is on point before hitting the recording
                  studio or taking the stage.
                </h3>
                <img
                  src={fac2}
                  alt="Jam Room"
                  className={`fac2 ${inView1 ? "animate" : ""}`}
                />
              </div>

              <div
                className={`fac3container  ${isActive3 ? "active3" : ""}`}
                onClick={() => CardInfo("facility3")}
                onMouseLeave={handleMouseLeave}
              >
                <h1>Recording Room A</h1>
                <h3 className={`fac3subtext ${isH1Displayed3 ? "displayed3" : ""}`}>
                  This room is optimized for recording vocals and acoustic
                  instruments, offering superior sound quality for capturing
                  drums, choirs, and symphonies. Comprehensive acoustic treatment
                  extends to the ceiling to eliminate standing waves and enhance
                  audio clarity.
                </h3>
                <img
                  src={fac3}
                  alt="Recording Room A"
                  className={`fac3 ${inView1 ? "animate" : ""}`}
                />
              </div>

              <div
                className={`fac4container  ${isActive4 ? "active4" : ""}`}
                onClick={() => CardInfo("facility4")}
                onMouseLeave={handleMouseLeave}
              >
                <h1>Equipment</h1>
                <h3 className={`fac4subtext ${isH1Displayed4 ? "displayed4" : ""}`}>
                Elevate your sound with premium gear, featuring AKG, Neumann, Kali, Yamaha, Behringer, and more, for unmatched mixing and mastering excellence.
                  
                </h3>
                <img
                  src={fac4}
                  alt="Equipment"
                  className={`fac4 ${inView1 ? "animate" : ""}`}
                />
              </div>

              <div
                className={`fac5container  ${isActive5 ? "active5" : ""}`}
                onClick={() => CardInfo("facility5")}
                onMouseLeave={handleMouseLeave}
              >
                <h1>Lounge </h1>
                <h3 className={`fac5subtext ${isH1Displayed5 ? "displayed5" : ""}`}>
                  Exciting lounge coming soon!
                </h3>
                <img
                  src={fac5}
                  alt="Lounge"
                  className={`fac5 ${inView1 ? "animate" : ""}`}
                />
              </div>
            </>
          )}
        </div>
               
        </div>
       
        
        </>
    );
}
export default Pagefive;
