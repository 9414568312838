import React, { useState, useEffect} from 'react';
import './BookingTwo.css';
import { Icon } from '@iconify/react';
import BookingStage from '../Mini/BookingStage';
import { useNavigate,useLocation } from 'react-router-dom';
const API_BASE = process.env.REACT_APP_API_BASE;

function BookingTwo() {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();

  const [UserDetails, setUserDetails] = useState({
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    PhoneNumber: '',
    Service: '',
  });

  const location = useLocation();
  const Type = location.state.Type;
  const slot = location.state.DateAndTime;

  
 
  const deletedLocked = async (bookingDate, Slots) => {
    const apiEndpoint = API_BASE + '/deleteLocked';

    try {
      const response = await fetch(apiEndpoint, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          BookingDate: bookingDate,
          BookingSlot: Slots,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // Handle success data if needed
    } catch (error) {
      // Handle errors
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if(Type==='Studio')
    {
      setUserDetails({ ...UserDetails, Service: 'Audio Recording' });
    }
    else
    {
      setUserDetails({ ...UserDetails, Service: 'Jam Room' });
    }
    const handleBeforeUnload = () => {
      // Provide the actual bookingDate and Slots values
      const bookingDate = slot.date;
      const slots = slot.selectedTimeSlots;

      // Call the deleteLocked function
      deletedLocked(bookingDate, slots);

      // Use navigator.sendBeacon to send a synchronous request in the background
      navigator.sendBeacon('/', JSON.stringify({ bookingDate, slots }));
    };

    // Attach the event listener for beforeunload
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [location.state.DateAndTime]);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate('/BookingConfirm', {state : {Details:UserDetails , Info:location.state.DateAndTime}});
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUserDetails({ ...UserDetails, [name]: value });
  };

  const handleServiceTypeInput = (e) => {
    const value = e.target.value;
    setUserDetails({ ...UserDetails, Service: value });
  };
  const handleBack =() =>
  {
    deletedLocked(slot.date,slot.selectedTimeSlots)
    navigate('/BookingTimeSlots', { state: { Type: Type} })
  }

  return (
    <>
    <Icon icon="material-symbols:arrow-back" id='back-arrow' onClick={handleBack}/>
      <BookingStage stage={2} />
      <div className="Booking-two">
      
        <div className="FormContainerOne">
          <form onSubmit={handleFormSubmit}>
            <h1>Name:</h1>
            <input
              type="text"
              name="FirstName"
              id="FirstName"
              placeholder="First Name"
              value={UserDetails.FirstName}
              onChange={handleInput}
              required
            />
            <input
              type="text"
              name="LastName"
              id="LastName"
              placeholder="Last Name"
              value={UserDetails.LastName}
              onChange={handleInput}
              required
            />
            <h1>Email Address:</h1>
            <h1>Contact Number:</h1>
            <input
              type="email"
              name="EmailAddress"
              id="EmailAddress"
              placeholder="Email Address"
              value={UserDetails.EmailAddress}
              onChange={handleInput}
              required
            />
            <input
              type="text"
              name="PhoneNumber"
              id="PhoneNumber"
              placeholder="Phone Number"
              value={UserDetails.PhoneNumber}
              onChange={handleInput}
              pattern="[0-9]{10}"
              title="Please enter a 10-digit number."
              required
            />
            <h1>Type of session:</h1>
            <div className={`dropdown ${isDropdownVisible ? 'show' : ''}`} required>
            <select name="TOS" id="TOS" onChange={handleServiceTypeInput}>
              <option value="" disabled>Select type of session:</option>
                  {Type === 'Jam Room' ? (
                    <>
                      <option value="Jam Room">Jam Room</option>
                    </>
                  ) : (
                      <>
                          <option value="Audio Recording">Audio Recording</option>
                          <option value="Music Production">Music Production</option>
                          <option value="Session Artist">Session Artist</option>
                          <option value="Video Recording">Video Recording</option>
                          <option value="Mixing and Mastering">Mixing and Mastering</option>
                      </>
                  )}
              </select>
              <Icon icon="simple-line-icons:arrow-up" rotate={2} id="icon" onClick={toggleDropdown} />
            </div>
            <input type="submit" id="Submit" value="Proceed" />
          </form>
        </div>
      </div>
    </>
  );
}

export default BookingTwo;
