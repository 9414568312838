import React,{useState,useEffect} from 'react'
import { useCookies } from "react-cookie";
import axios from "axios";
import './Security.css';
import DashboardMenu from '../Mini/DashboardMenu';
import DashboardMenuPayment from '../Mini/DashMenuPayment';
import { useNavigate } from 'react-router-dom';


const API_BASE = process.env.REACT_APP_API_BASE;

function Security() {

  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [userID, setuserID] = useState('')
  useEffect(() => {
    const verifyCookie = async () => {
      if(cookies.token==="undefined")
        {
          navigate("/login");
        }
      if (!cookies.token) {
        navigate("/login");
      }
      const { data } = await axios.post(
        `${API_BASE}/verify`,
        {},
        { withCredentials: true }
      );
      const { status, user } = data;
      setuserID(user)
      return status
        ? console.log("Booking")
        : (removeCookie("token"),navigate("/login"));
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE}/update-password/${userID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentPassword: currentPass, newPassword: newPass }),
      });

      const data = await response.json();

      if (response.ok) {
        alert(data.message);
        // Optionally, you can perform additional actions after successful update
      } else {
        alert(`Failed to update password: ${data.message}`);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Error updating password. Please try again later.');
    }
  };

  return (
    <>
      <div className="ReviewContainer">
        {userID === 'accounts_sjec' ? <DashboardMenuPayment /> : <DashboardMenu />}
        <h1 className="security-title">Security</h1>
        <div className="security-line" />
        <form onSubmit={handleFormSubmit}>
          <div className="security-input-fields">
            <label htmlFor="security-current-password-input" id="security-current-password-input-label">
              Enter Current Password:
            </label>
            <input
              type="password"
              id="security-current-password-input"
              name="security-current-password-input"
              placeholder="Current Password"
              value={currentPass}
              onChange={(e) => setCurrentPass(e.target.value)}
            />
            <label htmlFor="security-new-pass1-input" id="security-new-pass1-input-label">
              New Password:
            </label>
            <input
              type="password"
              id="security-new-pass1-input"
              name="security-new-pass1-input"
              placeholder="New Password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
            />
          </div>
          <input type="submit" value="Submit" id="security-submit-button" />
        </form>
      </div>
    </>
  );
}

export default Security;
