import React,{useState,useEffect,useLayoutEffect} from 'react'
import { useParams } from 'react-router-dom';
import './Pricing.css'
import paper from '../../Images/PrincingTop2.webp'
import PricingCard from '../Mini/PricingCard'
import PricingRectangle from '../../Images/PricingRectangle.png'


const categories = {
  musicProduction: [
    {
      title: 'Cover Song',
      amount: '3000',
      feature1: 'Starting Rate', 
      feature2: 'Instruments'
    },
    {
      title: 'Background Score',
      amount: '20000',
      feature1: 'Starting Rate', 
      feature2: 'Half Hour Movie'
    },
    {
      title: 'Band',
      amount: '10000',
      feature1: 'Starting Rate', 
      feature2: 'Live Artists'
    },
    {
      title: 'In the box',
      amount: '15000',
      feature1: 'Starting Rate', 
      feature2: 'Music Production'
    },
  ],
  audioRecording: [
    {
      title: 'Regular',
      amount: 'Variable',
      feature1: 'General Audio Recording', 
      feature2: 'Basic Mixing & Mastering'
    },
    {
      title: 'Standard',
      amount: 'Variable',
      feature1: 'Group or Solo Audio Recording', 
      feature2: 'Multiple tracks'
    },
    {
      title: 'Instrument',
      amount: 'Variable',
      feature1: 'Instrument Recording', 
      feature2: 'Maximum of 3 Instruments'
    },
    {
      title: 'Advanced',
      amount: 'Variable',
      feature1: 'Band and Instrument Recording', 
      feature2: 'Mixing and Mastering'
    },
    
  ],
  jamRoom: [
    {
      title: 'SJEC Students',
      amount: 'Free',
      feature1: 'SJEC students only', 
      feature2: 'Instruments can be requested'
    },
    {
      title: 'Alumni',
      amount: '400',
      feature1: 'Extra charges for instruments', 
      feature2: 'SJEC Alumni only'
    },
    {
      title: 'Standard',
      amount: '500',
      feature1: 'Extra charges for instruments', 
      feature2: 'General Use'
    },
    {
      title: 'Band',
      amount: '600',
      feature1: 'Sound system with microphones', 
      feature2: 'Instruments provided'
    }
  ],
  sessionArtist: [
    {
      title: 'Programming',
      amount: '8000',
      feature1: 'Starting Rate', 
      feature2: 'Logic Pro'
    },
    {
      title: 'Guitarist',
      amount: '3000',
      feature1: 'Rate per Song', 
      feature2: 'All guitars'
    },
    {
      title: 'Piano/Keyboard',
      amount: '5000',
      feature1: 'Starting Rate', 
      feature2: 'Arranger Workstation'
    },
    {
      title: 'Violin',
      amount: '3000',
      feature1: 'Rs. 3000 - Rs. 10000', 
      feature2: 'Audio Recording'
    },
    {
      title: 'Drummer',
      amount: '10000',
      feature1: 'Rate per song', 
      feature2: 'Mic setup'
    },
    {
      title: 'Flute',
      amount: '3000',
      feature1: 'Starting Rate', 
      feature2: 'Mic setup'
    },
  ],
  photoVideo: [
    {
      title: 'Photo',
      amount: '500',
      feature1: 'Photo Sessions inside the studio', 
      feature2: 'Extra charges for lighting'
    },
    {
      title: 'Inside the Studio',
      amount: '1000',
      feature1: 'Hourly Rate', 
      feature2: 'Includes Lighting'
    },
    {
      title: 'Green Screen',
      amount: '700',
      feature1: 'Hourly Rate', 
      feature2: 'Extra charges for equipment'
    },
    {
      title: 'Podcasts',
      amount: '700',
      feature1: 'Hourly Rate', 
      feature2: 'Rs. 800 for 2 cameras'
    }
    ,
    {
      title: 'Outdoor Recording',
      amount: '10000',
      feature1: 'Starting Rate', 
      feature2: 'Extra Charges for camera'
    }
    ,
    {
      title: 'Video Editing',
      amount: '5000',
      feature1: 'Starting Rate', 
      feature2: 'Mac 2020'
    }
    ,
    {
      title: 'Color Grading',
      amount: '5000',
      feature1: 'Starting Rate', 
      feature2: 'Mac 2020'
    }
    ,
  ],
  mixingMastering: [
    {
      title: 'Basic',
      amount: '3000',
      feature1: 'Rate per Song', 
      feature2: 'Limited Tracks'
    },
    {
      title: 'Regular',
      amount: '8000',
      feature1: 'Upto 15 tracks', 
      feature2: 'Layering & Mastering'
    },
    {
      title: 'Advanced',
      amount: '15000',
      feature1: 'More than 15 tracks', 
      feature2: 'Layering & Mastering'
    }
  ],

};

function Pricing() {
  const [category, setCategory] = useState('musicProduction'); 
  const { title } = useParams();
  const [PriceContainerHeight, setPriceContainerHeight] = useState(window.outerHeight);
    const [isMobile, setIsMobile] = useState(false);
    const checkIfMobile = () => {
      const isTopTabGone = window.matchMedia('(display-mode: standalone)').matches;
    
      if (isTopTabGone) {
        setPriceContainerHeight(window.outerHeight);
      } else {
        setPriceContainerHeight(window.innerHeight);
      }
    
      setIsMobile(window.innerWidth <= 767);
    };
    
    useLayoutEffect(() => {
      const handleResize = () => {
        checkIfMobile();
      };
    
      checkIfMobile();
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  useEffect(() => {
    if (title === 'Jam Room'){
      setCategory('jamRoom');
    }
    else
    if (title === 'Audio Recording'){
      setCategory('audioRecording');
    }
    else
    if (title === 'Session Artist'){
      setCategory('sessionArtist');
    }
    else
    if (title === 'Photo & Video'){
      setCategory('photoVideo');
    }
    else
    if (title === 'Mixing & Mastering'){
      setCategory('mixingMastering');
    }
  }, [title]);
  return (
    <>
        <div className="PricingPageContainer" style={isMobile ? { height: PriceContainerHeight } : {}}>
            <div className="paper-background">
                <img src={paper} alt="" />
                <h1 className='PricingTitle'>{title}</h1>
            </div>
              <div className="pricing-left-rectangle"/>
              <div className="CardContainer">
          {categories[category].map((pricingOption, index) => (
            <PricingCard
              category={category}
              key={index}
              title={pricingOption.title}
              amount={pricingOption.amount}
              features1={pricingOption.feature1}
              features2={pricingOption.feature2}
            />
          ))}
          {title === 'Jam Room' || title === 'Mixing & Mastering' ? (
              <h1 id="disclaimer">
                *Hourly rates apply to all services mentioned above.
              </h1>
            ) : (
              <h1 id="disclaimer">
                *Pricing varies according to the project's specific needs.
              </h1>
            )}
  
        </div>
        {isMobile && (
            <div className="Bottom-Rectangle">
              <img src={PricingRectangle} alt="" />
            </div>
          )}
        </div>
    </>
  )
}
export default Pricing