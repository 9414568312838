import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import './googlereview.css';

const MelodiumReviews = () => {
  const [reviews, setReviews] = useState([
    {
      author_name: 'Roger Loren',
      text: `This studio is a blessing for Mangalorean musicians. It's well-equipped and has excellent acoustics for all kinds of recording and mixing work. A notable feature is the super spacious live-room, which is the biggest I've seen in Mangalore. Another notable mention is their highly knowledgeable, musically-skilled and professional in-house engineer, Melroy Furtado.
      I had a great experience and would definitely recommend this place.`,
      rating: 5,
      time: new Date().getTime(), // Using the current time for simplicity
    },
    {
      author_name: 'Thea Mathias',
      text: `Amazing studio. Probably the best in mangalore. Good equipment. soundproofing is great. They’re definitely the best in town. Had an Amazing recording session here.`,
      rating: 5,
      time: new Date().getTime(), // Using the current time for simplicity
    },
    {
      author_name: 'Preemal Serrao',
      text: `One of the best Studios in Mangalore. Good Ambiance, Geat equipments. Comfortable and professional recording experience..`,
      rating: 5,
      time: new Date().getTime(), // Using the current time for simplicity
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to generate star icons based on the rating
  const generateStars = (rating) => {
    const starCount = Math.round(rating);
    return '⭐'.repeat(starCount);
  };

  // Function to truncate text to a specified number of words
  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  useEffect(() => {
    // The reviews are now hardcoded, so no need to fetch them
  }, []);

  return (
    <div>
      {loading && <p>Loading reviews...</p>}
      {error && <p>{error}</p>}
      {reviews.length > 0 && (
        <div className='ReviewContainer'>
          {reviews.map((review) => (
            <div key={review.time} className="review-card">
              <Icon icon="icomoon-free:quotes-left" className='quote-icon'/>
              <p className='review-review'>{truncateText(review.text, 50)}</p>
              <p className="review-rating" style={{ marginTop: '0.8vw' }}>{generateStars(review.rating)}</p>
              <p className="review-author" style={{ marginTop: '2vw' }}>{review.author_name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MelodiumReviews;
