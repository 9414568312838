import React, { useState, useLayoutEffect, useEffect } from "react";
import './Home.css'

const Home = () => {
  const HomeContainerHeight = window.innerHeight;
  
  const [isMobile, setIsMobile] = useState(false);
  const imgSource = isMobile
    ? "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/bvgi97gx60i8y4emqqpx"
    : "https://res.cloudinary.com/doernntq9/image/upload/f_auto,q_auto/v1/Website%20Static/t31e9mf9dj1dij0p31az";

  const checkIfMobile = () => {
    setIsMobile(window.innerWidth <= 767);
  };

  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = imgSource;
  }, [imgSource]);

  useLayoutEffect(() => {
    const handleResize = () => {
      checkIfMobile();
    };

    const debouncedHandleResize = debounce(handleResize, 300);

    checkIfMobile();
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  let handleClick = (type) => {
    let scrollToPosition = 0;

    if (!isMobile && type === "Booking") {
      scrollToPosition = ((window.innerHeight * 100)) / 100 + (window.innerWidth*273)/100;
    } else if (isMobile && type === "Booking") {
      scrollToPosition = (window.innerHeight * 465) / 100;
    }

    window.scrollTo({
      top: scrollToPosition,
      behavior: "smooth",
    });
  };

  return (
    <>
   
      <div className="homecontainer" style={isMobile ? { height: HomeContainerHeight } : {}}>
        <div className="background-image" style={isMobile ? { height: HomeContainerHeight } : {}}>
          <img src={imgSource} alt="Background" loading="eager"/>
        </div>
        <div className="rectangle1"></div>
        <div className="hero-text-container">
          <div className="herotext">
            <h1 id="thoughts-text">THOUGHTS, <span id="thoughts-span">IDEAS AND ART!</span></h1>
          </div>
          <div className="rectangle"></div>
        </div>
        <div className="rectangle2"></div>
        <div className="buttoncontainer">
          <button id="book-now-button" onClick={() => handleClick("Booking")}>
            BOOK NOW!
          </button>
        </div>

        <div className="sjecstudio">
          <h1>THE SJEC STUDIO</h1>
        </div>
      </div>
    </>
  );
};

export default Home;

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}
