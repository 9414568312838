import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import BookingSel from './Components/Pages/BookingSel';
import BookingOne from './Components/Pages/BookingOne';
import BookingTwo from './Components/Pages/BookingTwo';
import BookingThree from './Components/Pages/BookingThree'
import DashboardBooking from './Components/Pages/DashboardBooking';
import AddUser from './Components/Pages/addUser';
import Login from './Components/Pages/Login'
import Pricing from './Components/Pages/Pricing'
import BookingConfirmed from './Components/Pages/BookingConfirmed';
import Media from './Components/Pages/Media'
import Analytics from './Components/Pages/Analytics';
import Home from "./Components/Pages/Home";
import Navbar from './Components/Pages/Navbar';
import Pagetwo from './Components/Pages/Pagetwo';
import PageFour from './Components/Pages/Pagefour';
import Pagefive from './Components/Pages/Pagefive';
import Pagesix from './Components/Pages/Pagesix';
import Gallery from './Components/Pages/Gallery';
import Contact from './Components/Pages/Contact';
import Security from './Components/Pages/Security'
import PaymentUpdate from './Components/Pages/PaymentUpdate'
import FontLoader from './Components/Pages/FontLoader'
import Footer from './Components/Pages/Footer'
import "./Components/Pages/SliderStyles.css"

function App() {
  const [hasCompletedTimeSlots, setHasCompletedTimeSlots] = useState(false);
  const [hasLoggedIn, setLogin] = useState(false);
  const [UserID, setUser] = useState('');

  const handleLogout = () => {
    setLogin(false);
  };

  const handleLoginSuccess = (userId) => {
    setUser(userId);

  };


  return (
    <>
      <FontLoader />
      <div className='App'>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Pagetwo />
                <PageFour />
                <Pagefive />
                <Pagesix />
                <BookingSel />
                <Gallery />
                <Contact />
                <Footer />
              </>
            }
          />

          <Route path="/BookingTimeSlots" element={<BookingOne setHasCompletedTimeSlots={setHasCompletedTimeSlots} />} />
          <Route
            path="/BookingDetails"
            element={
              hasCompletedTimeSlots ? <BookingTwo /> : <Navigate to="/" />
            }
          />
          <Route
            path="/BookingConfirm"
            element={
              hasCompletedTimeSlots ? <BookingThree /> : <Navigate to="/" />
            }
          />
          <Route
            path="/ConfirmedBooking"
            element={
              hasCompletedTimeSlots ? <BookingConfirmed /> : <Navigate to="/" />
            }
          />
          <Route
            path="/Dashboard/Booking"
            element={<DashboardBooking />}
          />
          <Route path="/Dashboard/Login" element={<Login />} />
          <Route
            path="/Dashboard/Media"
            element={
              <Media />}
          />
          <Route
            path="/Dashboard/Analytics"
            element={
              <Analytics />}
          />
          <Route
            path="/Dashboard/AddUser"
            element={
              <AddUser />}
          />
          <Route
            path="/Dashboard/Security"
            element={
              <Security />}
          />
          <Route
            path="/Dashboard/Payment"
            element={
              hasLoggedIn && UserID === 'accounts_sjec' ? (
                <PaymentUpdate />
              ) : (
                <Navigate to="/Dashboard/Login" />
              )
            }
          />
          <Route
            path="/*"
            element={
              hasLoggedIn && UserID === 'accounts_sjec' ? (
                <Navigate to="/Dashboard/Payment" />
              ) : (
                <Navigate to="/Dashboard/Login" />
              )
            }
          />
          <Route path="/Dashboard" element={
            <Navigate to="/Dashboard/Booking" />} />
          <Route path="/Dashboard/AddUser" element={<AddUser />} />
          <Route
            path="/Pricing/:title"
            element={<Pricing />}
          />
          <Route path="/Booking" element={<BookingSel />} />
        </Routes>


      </div>
    </>
  );
}

export default App;